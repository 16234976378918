jQuery(document).ready(function($){

  /**
   * Metier filter
   */

   // Load more button 'onclick'
   $('.metier-loadmore').click(function(){

      var btn = $(this),
      data = {
        'action' : 'anefa_metier_loadmore',
        'query'  : anefaDatas.params, // that's how we get params from wp_localize_script() function
        'page'   : anefaDatas.currentPage
      };

      $.ajax({ // you can also use $.post here
        url       : anefaDatas.url, // AJAX handler
        data      : data,
        dataType  : 'json',
        type      : 'POST',
        beforeSend: function() {
          btn.attr('disabled', true);
          btn.addClass('running');
        },
        complete: function(){
          btn.attr('disabled', false);
          btn.removeClass('running');
        },
        success : function( posts ){
          if( posts ) {

            $( posts.data ).each(function() {
              $( this ).appendTo('#metier-wrap').hide().fadeIn(1000);
            });

          	anefaDatas.currentPage++;

          	if ( anefaDatas.currentPage === parseInt(anefaDatas.maxPage) ) {
              btn.hide(); // if last page, remove the button
            }

          } else {
          	btn.hide(); // if no data, remove the button as well
          }
        }
      });

    });

    // text filtering
  	$('#form-metier-recherche').on('submit', function(e) {

      e.preventDefault();
      // reset metier_category filter
      // BUG: this is not work my friend.
      $('#metier-categorie').val([]);
      // this work but trigger change event of select2js
      $('#metier-categorie').val('').trigger('change', [true]);

      // reset query
      anefaDatas.params = anefaDatas.query;

      var btn = $(this).find('button');

      var data = {
        'action' : 'anefa_metier_filter',
        'query'  : anefaDatas.params, // that's how we get params from wp_localize_script() function
        's'      : $('#metier-recherche').val()
      };

  		$.ajax({
  			url : anefaDatas.url,
  			data : data, // form data
  			dataType : 'json', // this data type allows us to receive objects from the server
  			type : 'POST',
        beforeSend: function() {
          btn.attr('disabled', true);
          $('.metier-filter').addClass('running');
          $('#metier-wrap').addClass('running');
        },
        complete: function(){
          btn.attr('disabled', false);
          $('.metier-filter').removeClass('running');
          $('#metier-wrap').removeClass('running');
        },
  			success : function( response ){
  				// when filter applied:
  				// set the current page to 1
  				anefaDatas.currentPage = 1;

  				// set the new query parameters
  				anefaDatas.params = response.data.posts;

  				// set the new max page parameter
  				anefaDatas.maxPage = response.data.max_page;

  				// insert the posts to the container
          $('#metier-wrap').html(response.data.content);

  				// hide load more button, if there are not enough posts for the second page
  				if ( response.data.max_page < 2 ) {
  					$('.metier-loadmore').hide();
  				} else {
  					$('.metier-loadmore').show();
  				}
  			}
  		});

  		// do not submit the form
  		return false;

  	});

    // metier_category filtering
    $('#metier-categorie').on('change', function(e, isReset) {

      // change triggered with reseting select2js on input text submit 
      if(isReset) {
        return false;
      }

      e.preventDefault();
      // reset metier search text input
      $('#metier-recherche').val('');

      // reset query
      anefaDatas.params = anefaDatas.query;

      var data = {
        'action'            : 'anefa_metier_filter',
        'query'             : anefaDatas.params, // that's how we get params from wp_localize_script() function
        'metier_category'   : $(this).val()
      };

      $.ajax({
        url : anefaDatas.url,
        data : data, // form data
        dataType : 'json', // this data type allows us to receive objects from the server
        type : 'POST',
        beforeSend: function() {
          $('.metier-filter').addClass('running');
          $('#metier-wrap').addClass('running');
        },
        complete: function(){
          $('.metier-filter').removeClass('running');
          $('#metier-wrap').removeClass('running');
        },
        success : function( response ){

          // when filter applied:
          // set the current page to 1
          anefaDatas.currentPage = 1;

          // set the new query parameters
          anefaDatas.params = response.data.posts;

          // set the new max page parameter
          anefaDatas.maxPage = response.data.max_page;

          // insert the posts to the container
          $('#metier-wrap').html(response.data.content);

          // hide load more button, if there are not enough posts for the second page
          if ( response.data.max_page < 2 ) {
            $('.metier-loadmore').hide();
          } else {
            $('.metier-loadmore').show();
          }
        }
      });

      // do not submit the form
      return false;

    });

});
